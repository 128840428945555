<template>
  <b-row>
    <b-col
      cols="12"
      offset-md="2"
      md="8"
      offset-lg="3"
      lg="6"
    >
      <b-card>
        <organization-payment />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import OrganizationPayment from '@/views/_global/payments/CustomerPayment'

export default {
  components: { OrganizationPayment },
}
</script>
